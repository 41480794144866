import {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  forwardRef,
} from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

// Assets
import Chevron from '@/assets/svg/chevron.svg'
import Cross from '@/assets/svg/cross.svg'
import DoubleArrows from '@/assets/svg/switch-arrows.svg'

// Components
import Button from '@/components/ui/button'
import Range from '@/components/ui/datePicker/Range'
import Single from '@/components/ui/datePicker/Single'
import Passengers from '@/components/search/Passengers'
import AirportsList from '@/components/search/AirportsList'
import Inspiration from '@/components/search/Inspiration'
import Popover from '@/components/ui/popover'
import Autocomplete from '@/components/ui/autocomplete'
import { SmartFilter } from '@/components/search/SmartFilter'

// Hooks
import { nextVersion, useMonotonicState } from '@/v2/common/monotonic-state'
import { nextMonth, prevMonth, getDays } from '@/logic/ContextualCalendarCache'
import useAirportSearch from '@/v2/location/AirportSearch'
import useDates from '@/hooks/dates'
import useClickOutside from '@/hooks/click-outside'
import useTranslation from '@/hooks/translation'
import usePrevious from '@/hooks/previous'
import usePrefersReducedMotion from '@/hooks/prefers-reduced-motion'
import useAnalytics from '@/hooks/analytics'
import useUser from '@/hooks/user'

import styles from './FormDesktop.module.css'
import { getPartnershipsCookie } from '@/utils/cookies'
import { MAINTENANCE } from '../constants'

const AirportAutoComplete = forwardRef(function AirportAutoComplete(
  { id, label, className, onSelect, selectedItem, error, isSecondary },
  ref
) {
  const searchAirports = useAirportSearch()

  return (
    <Autocomplete
      id={id}
      label={label}
      className={className}
      onChange={onSelect}
      search={searchAirports}
      noBorder
      noBackground
      selectedItem={selectedItem}
      error={error}
      inputClassName={isSecondary ? 'text-dark' : 'text-primary'}
      ref={ref}
    >
      {({ items, highlightedIndex }) => (
        <div className="absolute bg-white mt-5 rounded-5 shadow-double w-350 max-h-300 overflow-auto z-10">
          <AirportsList list={items} highlightedIndex={highlightedIndex} />
        </div>
      )}
    </Autocomplete>
  )
})

function TripTypeButton({ children, onClick, isSelected, isDark }) {
  const { isFlightClub } = useUser()
  return (
    <Button
      className={clsx(
        styles.tripTypeButton,
        isSelected && styles.tripTypeButtonSelected,
        isFlightClub && isSelected && styles.tripTypeButtonFlightClubSelected,
        isFlightClub &&
          isSelected &&
          isDark &&
          styles.tripTypeButtonFlightClubSelectedDark,
        !isSelected && {
          'text-white-60': isDark,
          'text-white text-shadow': !isDark,
          'bg-transparent': isFlightClub,
        }
      )}
      onClick={onClick}
      unstyled
      aria-pressed={isSelected}
    >
      {children}
    </Button>
  )
}

const Row = forwardRef(function Row(
  {
    search,
    rightElement,
    sliceId,
    minDate,
    showErrors,
    isSecondary,
    isMulti = false,
    nextField,
  },
  ref
) {
  const originRef = useRef()
  const destinationRef = useRef()
  const datesRef = useRef()
  const { formatDate } = useDates()
  const dates = search.getDates(sliceId)
  const { t } = useTranslation()
  const { slices, errors } = search
  const currentSlice = useMemo(
    () => slices.find((S) => S.id === sliceId),
    [slices]
  )
  const origin = currentSlice?.origin
  const destination = currentSlice?.destination
  const [displayedDays, setDisplayedDays] = useState([])
  const [contextualDays, setContextualDays] = useMonotonicState({})

  const currentSliceError = useMemo(
    () => errors?.slices?.find((S) => S.id === sliceId),
    [errors]
  )

  const switchAirports = useCallback(() => {
    search.setOrigin(destination, sliceId)
    search.setDestination(origin, sliceId)
  }, [sliceId, search, origin, destination])

  const showDateError =
    showErrors &&
    (search.errors.slices.find((s) => s.id === sliceId)?.date ||
      search.errors.missingReturnDate)

  const onDisplayedDaysChange = useCallback(
    (months) => {
      if (months?.length > 0) {
        setDisplayedDays([
          months[0]?.firstDayOfMonth,
          months[months.length - 1]?.lastDayOfMonth,
        ])
      }
    },
    [setDisplayedDays]
  )

  // the contextual days
  const updateEffect = async () => {
    // we can't search if no destination and no origin
    if (!origin || !destination) {
      return
    }

    let start, end
    if (displayedDays?.length > 0) {
      start = prevMonth(displayedDays[0])
      end = nextMonth(displayedDays[displayedDays.length - 1])
    } else {
      start = prevMonth(currentSlice?.date || new Date(), 2)
      end = nextMonth(currentSlice?.date || new Date(), 2)
    }

    const v = nextVersion()
    const r = await getDays(
      origin.value,
      destination.value,
      search.tripType,
      search.partnership,
      currentSlice?.date,
      start,
      end
    )
    setContextualDays(r || {}, v)
  }

  useEffect(() => {
    updateEffect()
  }, [
    search.tripType,
    search.partnership,
    currentSlice?.date,
    origin,
    destination,
    displayedDays,
  ])

  return (
    <div
      className={`w-full px-10 pb-10 last:pb-0 grid-cols-5 ${
        isMulti ? 'flex' : 'grid'
      } ${isSecondary ? 'gap-2' : 'gap-10'}`}
      ref={ref}
    >
      <div className="relative grid grid-cols-2 gap-2 col-span-2">
        <div className="">
          <AirportAutoComplete
            isSecondary={isSecondary}
            ref={originRef}
            id={`origin-${sliceId}`}
            key={`origin-${sliceId}`}
            label={t('components.formDesktop.origin')}
            className={`rounded-r-none ${
              isSecondary
                ? 'bg-gray-light shadow-none'
                : 'shadow-text hover:shadow-xl transform motion-safe:transition-all motion-safe:duration-200 bg-white'
            }`}
            onSelect={(val) => {
              search.setOrigin(val, sliceId)
              if (val && destinationRef.current) {
                destinationRef.current
                  .getElementsByTagName('input')
                  .item(0)
                  ?.focus()
              }
            }}
            selectedItem={origin}
            error={showErrors && currentSliceError?.origin}
          />
        </div>
        <Button
          className="flex items-center justify-center w-30 h-30 bg-white border border-dark-10 shadow-text rounded-full absolute z-20 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 hover:shadow-lg duration-200"
          onClick={switchAirports}
          unstyled
          aria-label={t('components.formDesktop.swap')}
        >
          <DoubleArrows />
        </Button>
        <div>
          <AirportAutoComplete
            isSecondary={isSecondary}
            ref={destinationRef}
            id={`destination-${sliceId}`}
            key={`destination-${sliceId}`}
            label={t('components.formDesktop.destination')}
            className={`rounded-l-none ${
              isSecondary
                ? 'rounded-r-none bg-gray-light shadow-none'
                : 'shadow-text hover:shadow-xl transform motion-safe:transition-all motion-safe:duration-200 bg-white'
            }`}
            onSelect={(val) => {
              search.setDestination(val, sliceId)
              if (val && datesRef.current) {
                datesRef.current.click()
              }
            }}
            selectedItem={destination}
            error={showErrors && currentSliceError?.destination}
          />
        </div>
      </div>
      <div className="flex-1">
        <Popover
          overlayClassName="ulysse-search-form"
          timeout={{ exit: 500, enter: 100 }}
          triggerRef={datesRef}
          trigger={
            <Button
              unstyled
              className={`relative w-full h-60 px-20 text-left ${
                showDateError ? 'ring-2 ring-danger' : ''
              } ${
                isSecondary
                  ? 'bg-gray-light'
                  : 'rounded-5 bg-white shadow-text hover:shadow-xl transform motion-safe:transition-all motion-safe:duration-200'
              }`}
              aria-labelledby={`dates-label-${sliceId}`}
              aria-describedby={`dates-description-${sliceId}`}
              aria-invalid={!!showDateError}
              type="button"
            >
              <span
                id={`dates-label-${sliceId}`}
                className={`absolute block ${
                  dates.length ? 'text-12 top-15' : 'text-base'
                } text-dark-60 leading-none`}
              >
                {search.tripType === 'roundtrip'
                  ? t('components.formDesktop.dates')
                  : t('components.formDesktop.date')}
              </span>
              <span
                id={`dates-description-${sliceId}`}
                className={`pt-15 block font-bold truncate ${
                  !isSecondary && 'text-primary'
                }`}
              >
                {search.tripType === 'roundtrip'
                  ? dates
                      .map((date) =>
                        formatDate(date, isSecondary ? 'd MMM' : 'E d MMM')
                      )
                      .join(' - ')
                  : dates[0]
                  ? formatDate(dates[0], 'E d MMM')
                  : null}
              </span>
            </Button>
          }
        >
          {({ transitionState, contentStyle, close }) => {
            const className =
              transitionState !== 'exited'
                ? {
                    'duration-500': true,
                    'opacity-0':
                      transitionState == 'exiting' ||
                      transitionState == 'entering',
                    'opacity-100 translate-y-0': transitionState == 'entered',
                    'translate-y-100':
                      transitionState == 'entering' && contentStyle?.bottom > 0,
                    '-translate-y-100':
                      transitionState == 'entering' && contentStyle?.top > 0,
                  }
                : 'opacity-0'

            return search.tripType === 'roundtrip' ? (
              <Range
                selectedDates={dates}
                className={className}
                onDisplayedDaysChange={onDisplayedDaysChange}
                contextualData={contextualDays}
                onSelectedDatesChange={(val, id) => {
                  search.setDates(val, id)
                  if (val.length === 2) {
                    close()
                    nextField?.()
                  }
                }}
              />
            ) : (
              <Single
                selectedDate={dates[0]}
                minDate={minDate}
                className={className}
                onDisplayedDaysChange={onDisplayedDaysChange}
                contextualData={contextualDays}
                onSelectedDatesChange={(val) => {
                  search.setDates(val, sliceId)
                  if (val.length === 1) {
                    close()
                    nextField?.()
                  }
                }}
              />
            )
          }}
        </Popover>
        {showDateError ? (
          <p className="text-danger text-14 mt-5">
            {search.errors.slices.find((s) => s.id === sliceId)?.date ||
              search.errors.missingReturnDate}
          </p>
        ) : null}
      </div>
      {rightElement ? rightElement : null}
    </div>
  )
})

const isInsideForm = (target) => {
  let node = target

  while (node.parentNode) {
    if (node.classList.contains('ulysse-search-form')) {
      return true
    }
    node = node.parentNode
  }

  return false
}

export default function FormDesktop({
  search,
  buttonText,
  isSecondary,
  hideTripType,
  isButtonDisabled,
  onCreate,
  prevFilters,
  hideHomeSmartFilters = true,
  acceptTripTypeOverride = false,
}) {
  const router = useRouter()
  const { track } = useAnalytics()
  const contentRef = useRef()
  const passengersRef = useRef()
  const addRowRef = useRef()

  const { isFlightClub } = useUser()
  const [isFocused, setIsFocused] = useState(false)
  const [isFocusedTransition, setIsFocusedTransition] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [smartFilters, setSmartFilters] = useState(
    prevFilters || {
      only_checked_bag_included: false,
      only_direct_flight: false,
      exclude_boeing: false,
    }
  )

  const onSmartFilters = (filter) => {
    setSmartFilters((prev) => {
      return {
        ...prev,
        [filter]: !prev[filter],
      }
    })
  }

  const prefersReducedMotion = usePrefersReducedMotion()

  useEffect(() => {
    if (isFocused && !isFocusedTransition) {
      setIsFocusedTransition(true)
    }
  }, [isFocused])

  const unfocus = () => {
    setIsFocusedTransition(false)

    if (prefersReducedMotion) {
      setIsFocused(false)
    } else {
      setTimeout(() => {
        setIsFocused(false)
      }, 200)
    }
  }

  const { t } = useTranslation()

  const onFocusChange = (e) => {
    if (contentRef.current && contentRef.current.contains(e.target)) {
      setIsFocused(true)
    }
  }

  useEffect(() => {
    document.addEventListener('focus', onFocusChange, true)
    return () => {
      document.removeEventListener('focus', onFocusChange, true)
    }
  })

  const prevOfferRequestId = usePrevious(router.query.id)

  useEffect(() => {
    if (
      router.query.id &&
      prevOfferRequestId !== router.query.id &&
      isSubmitting
    ) {
      setIsSubmitting(false)
    }
  })

  useEffect(() => {
    // Hiding form erros on each tripType change
    setShowErrors(false)
  }, [search.tripType])

  useClickOutside({
    contentRef,
    onClickOutside: (e) => {
      if (!isInsideForm(e.target)) {
        unfocus()
      }
    },
  })

  const passengers = (
    <Popover
      overlayClassName="ulysse-search-form"
      timeout={{ exit: 300, enter: 50 }}
      triggerRef={passengersRef}
      trigger={
        <Button
          unstyled
          className={`relative w-full h-60 px-20 text-left ${
            isSecondary
              ? 'rounded-r-5 bg-gray-light'
              : 'rounded-5 shadow-text bg-white hover:shadow-xl transform motion-safe:transition-all motion-safe:duration-200'
          }`}
          type="button"
          aria-label={`${search.longPassengersCabinClassDisplay}. ${t(
            'components.formDesktop.travelersAriaLabel'
          )}`}
        >
          <span
            id="passengers-label"
            className="absolute block text-12 top-15 text-dark-60 leading-none"
          >
            {t('components.formDesktop.travelersAndClass')}
          </span>
          <span
            className={`block font-bold pr-10 pt-15 truncate ${
              !isSecondary ? 'text-primary' : ''
            }`}
          >
            {search.shortPassengersCabinClassDisplay}
          </span>
          <span className="text-dark-60">
            <Chevron className="w-12 absolute right-20 top-1/2 transform -translate-y-1/2" />
          </span>
        </Button>
      }
    >
      {({ transitionState, contentStyle }) => (
        <Passengers
          numAdults={search.numAdults}
          numChildren={search.numChildren}
          numBabies={search.numBabies}
          onNumAdultsChange={search.setNumAdults}
          onNumChildrenChange={search.setNumChildren}
          onNumBabiesChange={search.setNumBabies}
          cabinClass={search.cabinClass}
          onCabinClassChange={search.setCabinClass}
          className={
            transitionState !== 'exited'
              ? {
                  'duration-300': true,
                  'opacity-0':
                    transitionState == 'exiting' ||
                    transitionState == 'entering',
                  'opacity-100 translate-y-0': transitionState == 'entered',
                  'translate-y-100':
                    transitionState == 'entering' && contentStyle?.bottom > 0,
                  '-translate-y-100':
                    transitionState == 'entering' && contentStyle?.top > 0,
                }
              : 'opacity-0'
          }
        />
      )}
    </Popover>
  )

  const submitButton = (
    <Button
      outline={isSecondary && isButtonDisabled}
      color={isSecondary && isButtonDisabled ? 'light' : 'primary'}
      className={clsx('form-desktop-submit w-full h-60', {
        'ml-5': isSecondary,
      })}
      size="xl"
      onClick={() => {
        let overridedTripType = null
        if (
          acceptTripTypeOverride &&
          search.tripType === 'roundtrip' &&
          search.getDates()?.length === 1
        ) {
          search.setTripType('oneway')
          overridedTripType = 'oneway'
        }
        setShowErrors(false)
        setIsSubmitting(true)
        search
          .createSearch({
            smartFilters,
            ignoreSpecialOffers: getPartnershipsCookie() === null,
            overridedTripType,
          })
          .then(({ data }) => {
            if (data?.id) {
              onCreate?.(data)

              unfocus()

              const query = { id: data.id }
              router.push({
                pathname: '/search/[id]',
                query,
              })
            } else {
              setIsSubmitting(false)
            }
          })
          .catch((err) => {
            setIsSubmitting(false)
            if (err?.valid === false) {
              setShowErrors(true)
            } else {
              console.error(err)
            }
          })
      }}
      loading={isSubmitting}
      disabled={MAINTENANCE || isButtonDisabled}
    >
      {buttonText || t('components.formDesktop.defaultButton')}
    </Button>
  )

  return (
    <>
      <div
        className={clsx(
          'motion-safe:transition-opacity motion-safe:duration-200',
          {
            'fixed top-0 right-0 bottom-0 left-0 bg-primary-dark z-10':
              isFocused,
            'opacity-90': isFocusedTransition,
            'opacity-0': !isFocusedTransition,
          }
        )}
      />
      <div ref={contentRef}>
        {!hideTripType ? (
          <div
            className="relative inline-block z-11"
            role="group"
            aria-label={t('components.formDesktop.tripType')}
          >
            <TripTypeButton
              isSelected={search.tripType === 'roundtrip'}
              onClick={() => {
                search.setTripType('roundtrip')
              }}
              isDark={isFocused}
            >
              {t('components.formDesktop.roundtrip')}
            </TripTypeButton>
            <TripTypeButton
              isSelected={search.tripType === 'oneway'}
              onClick={() => {
                search.setTripType('oneway')
              }}
              isDark={isFocused}
            >
              {t('components.formDesktop.oneway')}
            </TripTypeButton>
            <TripTypeButton
              isSelected={search.tripType === 'multi'}
              onClick={() => {
                search.setTripType('multi')
              }}
              isDark={isFocused}
            >
              {t('components.formDesktop.multi')}
            </TripTypeButton>
          </div>
        ) : null}
        <div className="relative w-full z-10">
          <div
            className={clsx({
              'shadow-md rounded-5 py-10': !isSecondary,
              'rounded-tl-none':
                !isSecondary && search.tripType === 'roundtrip',
              'bg-gray-light': !isSecondary && !isFlightClub,
              'bg-white-20': !isSecondary && isFlightClub && !isFocused,
              'bg-white-10': !isSecondary && isFlightClub && isFocused,
            })}
          >
            {search.tripType === 'inspiration' && (
              <Inspiration search={search} />
            )}
            {search.tripType === 'multi' && (
              <>
                {search.slices.map((slice, index) => (
                  <Row
                    isMulti
                    isSecondary={isSecondary}
                    key={slice.id}
                    search={search}
                    sliceId={slice.id}
                    minDate={search.getMinDate(slice.id)}
                    showErrors={showErrors}
                    rightElement={
                      search.slices.length > 2 ? (
                        <Button
                          type="button"
                          className="h-60 w-60 text-primary"
                          aria-label={t('components.formDesktop.removeFlight', {
                            index: index + 1,
                          })}
                          onClick={() => {
                            search.removeSlice(slice.id)
                          }}
                          unstyled
                        >
                          <span className="inline-block mx-auto">
                            <Cross className="w-15" />
                          </span>
                        </Button>
                      ) : (
                        <div className="w-60 h-60" />
                      )
                    }
                  />
                ))}
                <div className="pb-10 px-10 border-b border-dark-10">
                  <Button
                    outline
                    onClick={() => {
                      search.addSlice({})
                    }}
                    color={isFlightClub ? 'white' : 'primary'}
                    disabled={search.slices.length >= 10}
                    ref={addRowRef}
                  >
                    {t('components.formDesktop.addFlight')}
                  </Button>
                </div>
                <div className="flex justify-end px-10 pt-10">
                  <div className="relative mr-10 w-200">{passengers}</div>
                  <div className="w-200">{submitButton}</div>
                </div>
              </>
            )}
            {(search.tripType === 'roundtrip' ||
              search.tripType === 'oneway') && (
              <Row
                isSecondary={isSecondary}
                search={search}
                sliceId={search.slices[0].id}
                showErrors={showErrors}
                nextField={() => passengersRef.current?.click()}
                rightElement={
                  <>
                    <div className="flex-1 relative min-w-0">{passengers}</div>
                    <div className="flex-1">{submitButton}</div>
                  </>
                }
              />
            )}
          </div>
          {/* filters section */}
          {!hideHomeSmartFilters && search.tripType != 'inspiration' ? (
            <div className="flex mt-10 mb-30">
              <SmartFilter
                onClick={() => {
                  track('filter_added_homepage', {
                    filters_added: 'hold_luggage_included',
                  })
                  onSmartFilters('only_checked_bag_included')
                }}
                isActive={smartFilters.only_checked_bag_included}
                activeClassName={
                  isFlightClub && 'text-white border-transparent'
                }
                className={clsx('mr-10 flex-shrink-0 whitespace-nowrap', {
                  'bg-white': !isFlightClub,
                  'bg-white-20 text-white border-transparent':
                    isFlightClub && !isFocused,
                  'bg-white-10 text-white border-transparent':
                    isFlightClub && isFocused,
                })}
              >
                {t('components.searchFilters.checkedLuggageIncluded')}
              </SmartFilter>
              <SmartFilter
                onClick={() => {
                  track('filter_added_homepage', {
                    filters_added: 'direct_flights',
                  })
                  onSmartFilters('only_direct_flight')
                }}
                isActive={smartFilters.only_direct_flight}
                activeClassName={
                  isFlightClub && 'text-white border-transparent'
                }
                className={clsx('mr-10 flex-shrink-0 whitespace-nowrap', {
                  'bg-white': !isFlightClub,
                  'bg-white-20 text-white border-transparent':
                    isFlightClub && !isFocused,
                  'bg-white-10 text-white border-transparent':
                    isFlightClub && isFocused,
                })}
              >
                {t('components.searchFilters.direct')}
              </SmartFilter>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
